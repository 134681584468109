import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Container,
  Stack,
  Divider,
  Drawer,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { memo, useMemo, useState, useRef, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'src/components/snackbar';
import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { ColorPicker } from 'src/components/color-utils';
import { FORMDETAILSADDGROUP } from 'src/_mock/_formdetailsaddgroup';
import { AVAILABILITY } from 'src/_mock/_availability';
import { COLOR_OPTIONS } from 'src/_mock/_calendar';
import { palette as themePalette } from 'src/theme/palette';
import {
  createImage,
  createMenuGroup,
  editGroup,
  editMenu,
  useGetAllItems,
  useGetAllPrepStations,
} from 'src/api/apis';
import { mutate } from 'swr';
import { endpoints } from 'src/utils/axios';
import {
  filterMyItem,
  removeModifier,
  removeSaveMyItem,
  replaceMyGroup,
  replaceMyItem,
  saveMyGroup,
  saveMyItem,
} from 'src/features/group/groupSlice';
import Iconify from '../iconify';
import { RHFSelect, RHFTextField, RHFMultiCheckbox, RHFSwitch, RHFUpload } from '../hook-form';
import FormProvider from '../hook-form/form-provider';
import AddItem from '../add-item/add-item';

const AddGroup = ({
  currentMenu,
  pathname,
  createMenuRef,
  closeFunc,
  breadcrumb,
  groupItem,
  edit,
  flow,
  showgroup,
}) => {
  const { data: prep } = useGetAllPrepStations();
  const { data: optionItems } = useGetAllItems();

  const updatedPrepStations = prep?.map((item) => ({
    value: item.prepStationId,
    label: item.description,
    prepStation: item.item,
  }));

  // eslint-disable-next-line no-unused-vars
  const [showOptions, setshowOptions] = useState(showgroup);
  const { enqueueSnackbar } = useSnackbar();
  const [openItemDrawer, setopenItemDrawer] = useState(false);
  const [editOption, seteditOption] = useState(false);
  const selectedObjectRef = useRef(null);
  const [selectedObject, setselectedObject] = useState({});
  const savedItem = useSelector((state) => state.group.saveditem);
  const savedgroup = useSelector((state) => state.group.savedgroup);
  const [setingFlow, setsetingFlow] = useState(flow);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatchedItemsRef = useRef(new Set());

  const MenuSchema = Yup.object().shape({
    groupName: Yup.string().required('Name is required'),
    availability: Yup.bool().required('availability is required'),
    // nickName: Yup.string(),
    color: Yup.string(),
    // kitchenNickname: Yup.string(),
    // kdsColor: Yup.string(),
    prepStations: Yup.array().min(1, 'Choose at least one option'),
    // course: Yup.string(),
    pos: Yup.bool(),
    kiosk: Yup.bool(),
    onlineOrder: Yup.bool(),
    menuGroups: Yup.array(),
  });

  const defaultValues = useMemo(() => {
    if (edit && groupItem) {
      return {
        groupName: groupItem?.groupName || '',
        availability: groupItem?.availability || false,
        // nickName: groupItem?.nickName || '',
        color: groupItem?.color || '',
        // kdsColor: groupItem?.kdsColor || '',
        prepStations: groupItem?.prepStations || [],
        // course: groupItem?.course || '',
        pos: groupItem?.pos || false,
        kiosk: groupItem?.kiosk || false,
        onlineOrder: groupItem?.onlineOrder || false,
        image: groupItem?.image || null,
      };
      // eslint-disable-next-line no-else-return
    } else {
      return {
        groupName: currentMenu?.itemName || '',
        availability: currentMenu?.availability || false,
        // nickName: currentMenu?.nickName || '',
        color: currentMenu?.color || '',
        // taxRates: currentMenu?.taxRates || [],
        // calculatedPrice: currentMenu?.calculatedPrice || false,
        // taxException: currentMenu?.taxException || false,
        // kitchenNickname: currentMenu?.kitchenNickname || '',
        // kdsColor: currentMenu?.kdsColor || '',
        prepStations: currentMenu?.prepStations || [],
        // course: currentMenu?.course || '',
        pos: currentMenu?.pos || false,
        kiosk: currentMenu?.kiosk || false,
        onlineOrder: currentMenu?.onlineOrder || false,
        image: currentMenu?.image || null,
      };
    }
  }, [edit, currentMenu, groupItem]);

  const palette = themePalette('light');

  const methods = useForm({
    resolver: yupResolver(MenuSchema),
    defaultValues,
  });

  const { control, handleSubmit, reset, watch, setValue } = methods;
  const values = watch();

  useEffect(() => {
    reset(defaultValues);
    seteditOption(false);
    if (edit && groupItem?.menuItems) {
      dispatch(replaceMyItem(groupItem?.menuItems));
    }
  }, [reset, defaultValues, flow, dispatch, groupItem, edit]);

  const items = [];

  const onSubmit = handleSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    try {
      let mydata = { ...data };
      // if (savedModifiers?.length > 0) {
      //   const extractedModifierIds = savedModifiers.map((item) => item.modifierId);
      //   mydata.modifierGroups = extractedModifierIds;
      // }
      if (savedItem?.length > 0) {
        const extractedIds = savedItem.map((item) => item.menuItemId);
        mydata = {
          ...mydata,
          items,
          menuId: groupItem?.menuId,
          menuItems: extractedIds.flat(),
        };
      } else {
        mydata = {
          ...mydata,
          items,
          menuId: groupItem?.menuId,
          menuItems: [],
        };
      }
      if (data.image instanceof File) {
        const response = await fetch(data.image.preview);
        const blob = await response.blob();
        const Filename = extractFileName(data.image.path);
        const imgRes = await createImage(blob, Filename);
        mydata.image = imgRes.data[0];
      }

      mydata.image = mydata.image === null || undefined ? {} : mydata.image;
      let res;
      if (edit) {
        res = await editGroup(groupItem?.menuGroupId, mydata);
      } else if (edit === false && flow === false) {
        res = await createMenuGroup(mydata);
        if (groupItem) {
          const updatedGroup = {
            ...groupItem,
            menuGroups: [...(groupItem.menuGroups || []), res.data],
          };
          updatedGroup.menuGroups = updatedGroup.menuGroups.map((item) => item.menuGroupId);
          // await editGroup(updatedGroup.menuGroupId, updatedGroup);
          await editMenu(updatedGroup.menuId, updatedGroup);
        }
      } else {
        res = await createMenuGroup(mydata);
      }
      setSelectedOptions([]);
      dispatchedItemsRef.currentItem.clear();
      if (res.status !== 200 || res.status === 404) {
        enqueueSnackbar('Unable to save the changes', { variant: 'error' });
        handeSaveClose();
      } else if (res.status === 200) {
        enqueueSnackbar('changes successfully added!', { variant: 'success' });
        if (flow && !edit) {
          const extracteditems = savedItem.map((item) => item);
          const newdata = { ...res.data, menuItems: extracteditems };
          dispatch(saveMyGroup(newdata));
          dispatch(removeSaveMyItem());
          setshowOptions(res?.data.menuGroupId);
        }
        if (edit) {
          const editedItem = savedgroup.map((item) => {
            if (item?.menuGroupId === res?.data?.menuGroupId) {
              return res.data;
            }
            return item;
          });

          dispatch(replaceMyGroup(editedItem));
        }
        dispatch(removeSaveMyItem());
        dispatch(removeModifier());
        handeSaveClose();
      }
    } catch (error) {
      console.error('Error in onSubmit:', error);
      setLoading(false);
      handeSaveClose();
    }
    setItemList([]);
    setLoading(false);
    reset();
  });

  const handleCloseItemDrawer = useCallback(() => {
    seteditOption(false);
    setopenItemDrawer(false);
  }, []);
  const handleOpenItemDrawer = useCallback(() => {
    setsetingFlow(true);
    seteditOption(false);
    setopenItemDrawer(true);
  }, []);

  const handleCloseItemDrawer2 = (val) => {
    setopenItemDrawer(val);
    mutate(endpoints.menuItem.menu);
  };

  const breadcrumblist = [...breadcrumb];

  const handleAutocompleteChange = (event, newValue, reason, detail) => {
    if (reason === 'removeOption') {
      if (dispatchedItemsRef.current.has(detail.option.menuItemId)) {
        dispatchedItemsRef.current.delete(detail.option.menuItemId);
      }
    }

    setSelectedOptions(newValue);

    newValue.forEach((item) => {
      if (!dispatchedItemsRef.current.has(item.menuItemId)) {
        dispatch(saveMyItem(item));
        dispatchedItemsRef.current.add(item.menuItemId);
      }
    });
  };
  // Delete Item Option
  const deleteFunc = (item) => {
    setselectedObject(item);
    dispatchedItemsRef.current.delete(item.menuItemId);
    dispatch(filterMyItem(selectedObject.menuItemId));
  };

  // const [selectedModifiers, setselectedModifiers] = useState([]);
  // const timeoutRef2 = useRef(null);
  // const dispatchedModifierRef = useRef(new Set());
  // const handleAutocompleteChange2 = (event, newValue) => {
  //   setselectedModifiers(newValue);

  //   if (timeoutRef2.current) {
  //     clearTimeout(timeoutRef2.current);
  //   }

  //   // Set a new timeout
  //   timeoutRef2.current = setTimeout(() => {
  //     newValue.forEach((item) => {
  //       if (!dispatchedModifierRef.current.has(item.modifierId)) {
  //         dispatch(saveModifier(item));
  //         dispatchedModifierRef.current.add(item.modifierId);
  //       }
  //     });
  //   }, 1500);
  // };

  const [ItemList, setItemList] = useState([]);
  useEffect(() => {
    setItemList(optionItems);
  }, [optionItems]);

  // const [ModifierList, setModifierList] = useState([]);
  // useEffect(() => {
  //   if (modifiersList != null || modifiersList !== undefined) {
  //     setModifierList(modifiersList);
  //   }
  //   if (groupItem?.modifierGroups?.length > 0) {
  //     const modifiersId = new Set(groupItem.modifierGroups.map((item) => item));
  //     // Filter optionItems to exclude those that are already in savedgroup
  //     const myfilteredItems = modifiersList.filter((item) => !modifiersId.has(item.modifierId));
  //     const includedModifiers = modifiersList.filter((item) => modifiersId.has(item.modifierId));
  //     dispatch(replacAllModifiers(includedModifiers));
  //     setModifierList(myfilteredItems);
  //   }
  // }, [dispatch, groupItem, modifiersList, optionItems]);

  const handleClose = () => {
    // setselectedModifiers([]);
    dispatch(removeSaveMyItem());
    closeFunc(false);
  };
  const handeSaveClose = useCallback(() => {
    setTimeout(() => {
      // setselectedModifiers([]);
      closeFunc();
    }, 100);
  }, [closeFunc]);
  const addGroupRef = useRef(null);
  const scrollToSection = useCallback((section) => {
    if (addGroupRef.current) {
      const targetSection = addGroupRef.current.querySelector(`#${section.replace(/ /g, '-')}`);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });

        // Check if the last item is visible and adjust the scroll
        const lastItem = FORMDETAILSADDGROUP[FORMDETAILSADDGROUP.length - 1];
        const lastItemElement = addGroupRef.current.querySelector(
          `#${lastItem.replace(/ /g, '-')}`
        );
        const containerBottom = addGroupRef.current.getBoundingClientRect().bottom;
        const lastItemBottom = lastItemElement.getBoundingClientRect().bottom;

        if (lastItemBottom > containerBottom) {
          addGroupRef.current.scrollTo({
            top: addGroupRef.current.scrollTop + (lastItemBottom - containerBottom),
            behavior: 'smooth',
          });
        }
      }
    }
  }, []);

  // Edit Item Option
  const drawerItem = (item2) => {
    seteditOption(true);
    selectedObjectRef.current = item2;
    setopenItemDrawer(true);
  };

  // file functions
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue('image', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('image', []);
  }, [setValue]);

  function extractFileName(s) {
    const dotIndex = s.indexOf('.');
    return dotIndex !== -1 ? s.substring(0, dotIndex) : s;
  }

  /// container functions
  const renderInfo = (
    <Stack
      id="General-Info"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, boxShadow: 3 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px' }}>
          General Info{' '}
        </Typography>
      </Box>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Group Name</Typography>
        <RHFTextField name="groupName" label="Group Name" />
      </Stack>
      <Stack spacing={1.5} sx={{ my: 2, width: '300px' }}>
        <RHFSelect
          native
          name="availability"
          label="Availability"
          InputLabelProps={{ shrink: true }}
        >
          {AVAILABILITY?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Stack>

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Pos nickname (if different than item)</Typography>
        <RHFTextField name="nickName" label="Pos nickname (if different than item)" />
      </Stack> */}

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography> POS Color Button</Typography>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={COLOR_OPTIONS}
            />
          )}
        />
      </Stack>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Image</Typography>
        <RHFUpload
          thumbnail
          name="image"
          maxSize={20000000}
          onDrop={handleDrop}
          onDelete={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={() => console.info('ON UPLOAD')}
        />
      </Stack>
    </Stack>
  );

  const Items = (
    <Box
      id="Items"
      sx={{
        backgroundColor: 'white',
        width: '100%',
        p: 2,
        my: 2,
        boxShadow: 3,
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'left',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px', mr: 4 }}>
          Items
        </Typography>
      </Box>

      <Box sx={{ width: '100%' }}>
        {savedItem?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 2,
              boxShadow: '0px 10px 5px rgba(0, 167, 111, 1)',
              border: 1,
              borderRadius: '8px',
              my: 2,
              width: '100%',
            }}
          >
            <Box>
              <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
                {item?.menuItemName}
              </Typography>
              <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: '400px' }}>
                {item?.description}
              </Typography>
            </Box>
            <Box>
              <Iconify
                onClick={(event) => {
                  setselectedObject(item);
                  // selectedObjectRef.current = item;

                  drawerItem(item);
                }}
                icon="eva:edit-outline"
                sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
              />
              <Iconify
                onClick={(event) => {
                  deleteFunc(item);
                }}
                icon="eva:trash-2-outline"
                sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleOpenItemDrawer()}
          sx={{ width: '200px', mt: 4 }}
        >
          <Box sx={{ mr: 1 }}>+</Box> Add A New Item
        </Button>

        {/* <Button
          variant="outlined"
          color="success"
          sx={{ width: '200px', mt: 4 }}
          onClick={() => handleOpenExistingItemDrawer()}
        >
          <Box sx={{ mr: 1 }}>+</Box> Add an Existing Item
        </Button> */}

        <Stack spacing={1.5} sx={{ my: 2, width: '80%' }}>
          <Container>
            <Autocomplete
              multiple
              id="tags-standard"
              options={ItemList || []}
              getOptionLabel={(option) => option?.menuItemName}
              filterSelectedOptions
              value={selectedOptions}
              // defaultValue={[top100Films[13]]}
              onChange={handleAutocompleteChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Add from existing items"
                  // placeholder="Favorites"
                />
              )}
            />
          </Container>
        </Stack>
      </Box>
    </Box>
  );

  const KitchenPrep = (
    <Stack
      id="Kitchen-prep"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 4, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px' }}>
        Kitchen prep
      </Typography>
      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Kitchen nickname (if different than group)</Typography>
        <RHFTextField name="kitchenNickname" />
      </Stack> */}

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography> KDS color</Typography>
        <Controller
          name="kdsColor"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={COLOR_OPTIONS}
            />
          )}
        />
      </Stack> */}

      <Stack spacing={3} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Prep Stations</Typography>
        <Typography>
          Items ordered from this menu are routed to the kitchens you select below
        </Typography>
        <RHFMultiCheckbox
          sx={{ display: 'flex', flexDirection: 'column' }}
          row
          spacing={4}
          name="prepStations"
          options={updatedPrepStations}
        />
      </Stack>
    </Stack>
  );
  const ChannelVisibility = (
    <Stack
      id="Channel-Visibility"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 4, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px', my: 2 }}>
        Channel Visibility
      </Typography>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              POS
            </Typography>
          </Box>
          <RHFSwitch name="pos" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              KIOSK
            </Typography>
          </Box>

          <RHFSwitch name="kiosk" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            my: 2,
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography sx={{ color: 'black', fontSize: '12px', fontWeight: '600px' }}>
              Online Ordering
            </Typography>
          </Box>

          <RHFSwitch name="onlineOrder" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
    </Stack>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
        backgroundColor: '#F5F5F5',
        // overflowY: 'auto',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box sx={{ backgroundColor: '#F5F5F5', height: '80vh' }}>
            <Stack sx={{ pt: 2, backgroundColor: '#F5F5F5' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'between',
                  alignItems: 'center',

                  px: 10,
                }}
              >
                <Iconify
                  icon="eva:close-fill"
                  sx={{
                    width: '30px',
                    height: '30px',
                    pl: '-2rem',
                    cursor: 'pointer',
                  }}
                  onClick={handleClose}
                />
                <Typography sx={{ fontSize: '32px', lineHeight: '48px', fontWeight: '700px' }}>
                  Menu Categories
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 14,
                }}
              >
                {breadcrumb && <CustomBreadcrumbs links={breadcrumb} sx={{ pb: 5 }} />}
                <Button
                  size="large"
                  color="success"
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Stack>

            <Scrollbar
              sx={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}
            >
              <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Stack sx={{ position: 'fixed', top: '200px' }}>
                    {FORMDETAILSADDGROUP?.map((item, index) => (
                      <Box
                        sx={{
                          fontSize: '14px',
                          fontWeight: '600',
                          color: '#8E8E8E',
                          borderLeft: 4,
                          borderColor: 'gray',
                          px: '8px',
                          py: '8px',
                          '&:hover': {
                            color: `${palette.primary.main}`,
                            borderColor: `${palette.primary.main}`,
                          },
                          cursor: 'pointer',
                        }}
                        key={index}
                        onClick={() => scrollToSection(item)}
                      >
                        {item}
                      </Box>
                    ))}
                  </Stack>
                </Grid>
                <Grid
                  ref={addGroupRef}
                  sm={8}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Container>
                    <Stack sx={{ width: '90%' }}>
                      {renderInfo}
                      {/* {PricingTaxes} */}
                      {Items}
                      {/* {ModifierGroups} */}
                      {/* {MenuGroups} */}
                      {KitchenPrep}
                      {ChannelVisibility}
                    </Stack>
                  </Container>
                </Grid>
              </Grid>
            </Scrollbar>
          </Box>
        </FormProvider>
      </Stack>

      <Drawer
        open={openItemDrawer}
        anchor="right"
        onClose={handleCloseItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddItem
          breadcrumb={breadcrumblist}
          flow={setingFlow}
          closeItem={handleCloseItemDrawer2}
          edit={editOption}
          drawerItem={selectedObject || selectedObjectRef?.current}
        />
      </Drawer>
    </Box>
  );
};

AddGroup.propTypes = {
  currentMenu: PropTypes.object,
  pathname: PropTypes.string,
  createMenuRef: PropTypes.object,
  closeFunc: PropTypes.func,
  breadcrumb: PropTypes.array,
  groupItem: PropTypes.object,
  edit: PropTypes.bool,
  flow: PropTypes.bool,
  showgroup: PropTypes.bool,
};

export default memo(AddGroup);
