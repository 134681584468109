import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Container,
  Stack,
  Divider,
  Drawer,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { memo, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Unstable_Grid2';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { ColorPicker } from 'src/components/color-utils';
import { FORMDETAILSADDITEMS } from 'src/_mock/_formdetailsadditem';
import { AVAILABILITY } from 'src/_mock/_availability';
import { COLOR_OPTIONS } from 'src/_mock/_calendar';
import { useSnackbar } from 'src/components/snackbar';
import { palette as themePalette } from 'src/theme/palette';
import { mutate } from 'swr';
import { endpoints } from 'src/utils/axios';
import {
  createMenuItem,
  useGetTaxRates,
  EditMenuItem,
  useGetAllPrepStations,
  createImage,
  useGetModifiers,
} from 'src/api/apis';
import {
  deleteAModifier,
  removeModifier,
  replacAllModifiers,
  replaceMyItem,
  saveModifier,
  saveMyItem,
} from 'src/features/group/groupSlice';
import Iconify from '../iconify';
import { RHFSelect, RHFTextField, RHFUpload, RHFMultiCheckbox, RHFSwitch } from '../hook-form';
import FormProvider from '../hook-form/form-provider';
// eslint-disable-next-line import/no-cycle
import AddModifier from '../add-modifier/add-modifier';

const AddItem = ({ pathname, createMenuRef, closeItem, breadcrumb, drawerItem, edit, flow }) => {
  // Api handling
  const { data: taxrates } = useGetTaxRates();
  const { data: prep } = useGetAllPrepStations();
  const { enqueueSnackbar } = useSnackbar();
  const updatedTaxRates = taxrates
    ? taxrates.map((taxRate) => ({
        value: taxRate.taxRateId,
        label: taxRate.description,
        taxRate: taxRate.taxRate,
      }))
    : [];

  const updatedPrepStations = prep
    ? prep.map((myitem) => ({
        value: myitem.prepStationId,
        label: myitem.description,
        prepStation: myitem.item,
      }))
    : [];

  const dispatch = useDispatch();
  const savedItem = useSelector((state) => state.group.saveditem);

  const MenuSchema = Yup.object().shape({
    menuItemName: Yup.string().required('Item Name is required'),
    availability: Yup.boolean().required('availability is required'),
    color: Yup.string(),
    image: Yup.mixed().nullable(),
    description: Yup.string(),
    taxRates: Yup.array().min(1, 'select atleast 1'),
    itemCode: Yup.string(),
    prepStations: Yup.array(),
    pos: Yup.bool(),
    kiosk: Yup.bool(),
    onlineOrder: Yup.bool(),
    PriceStrategy: Yup.string(),
    price: Yup.number().min(1, 'minimum price should be grater than 0'),
  });

  const defaultValues = useMemo(
    () => ({
      menuItemName: drawerItem?.menuItemName || '',
      availability: drawerItem?.availability !== undefined ? drawerItem.availability : false,
      color: drawerItem?.color || '',
      description: drawerItem?.description || '',
      itemCode: drawerItem?.itemCode || '',
      taxRates: drawerItem?.taxRates || [],
      prepStations: drawerItem?.prepStations || [],
      pos: drawerItem?.pos !== undefined ? drawerItem.pos : false,
      kiosk: drawerItem?.kiosk !== undefined ? drawerItem.kiosk : false,
      onlineOrder: drawerItem?.onlineOrder !== undefined ? drawerItem.onlineOrder : false,
      PriceStrategy: drawerItem?.priceStrategy || 'nothing',
      price: drawerItem?.price || 0,
      image: drawerItem?.image || null,
    }),
    [drawerItem]
  );

  const palette = themePalette('light');

  const methods = useForm({
    resolver: yupResolver(MenuSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    // const fetchData = async () => {
    //   await useGetAnItem();
    // };
    // Set default values when component is mounted
    reset(defaultValues);
  }, [reset, defaultValues]);
  const values = watch();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue('image', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('image', []);
  }, [setValue]);

  function extractFileName(s) {
    const dotIndex = s.indexOf('.');
    return dotIndex !== -1 ? s.substring(0, dotIndex) : s;
  }

  // Modifier
  const { data: modifiersList } = useGetModifiers();

  const [openExistingItemDrawer, setopenExistingItemDrawer] = useState(false);
  const [editModifier, seteditModifier] = useState(false);
  const savedModifiers = useSelector((state) => state.group.savedmodifiers);
  const [selectedModifierObject, setselectedModifierObject] = useState({});
  const [ModifierList, setModifierList] = useState([]);
  const [selectedModifiers, setselectedModifiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatchedModifierRef = useRef(new Set());

  useEffect(() => {
    if (modifiersList != null || modifiersList !== undefined) {
      setModifierList(modifiersList);
    }

    if (drawerItem?.modifierGroups?.length > 0) {
      // Create a set of modifierIds instead of the full objects
      const modifiersId = new Set(drawerItem.modifierGroups.map((item) => item.modifierId));

      // Filter modifiersList to include only those with modifierId in the Set
      const includedModifiers = modifiersList.filter((item) => modifiersId.has(item.modifierId));

      // Store the modifierIds in the dispatchedModifierRef
      includedModifiers.forEach((item) => dispatchedModifierRef.current.add(item.modifierId));

      dispatch(replacAllModifiers(includedModifiers));
      setModifierList(modifiersList);
    }
  }, [dispatch, drawerItem, edit, modifiersList]);

  const handleCloseExistingItemDrawer = () => {
    setopenExistingItemDrawer(false);
    dispatch(removeModifier());
    mutate(endpoints.modifier.modifiers);
  };
  const handleOpenExistingItemDrawer = useCallback(() => {
    setopenExistingItemDrawer(true);
  }, []);

  const handleAutocompleteChange2 = (event, newValue, reason, detail) => {
    if (reason === 'removeOption') {
      if (dispatchedModifierRef.current.has(detail.option.menuGroupId)) {
        dispatchedModifierRef.current.delete(detail.option.menuGroupId);
      }
    }
    setselectedModifiers(newValue);

    // Set a new timeout

    newValue.forEach((item) => {
      if (!dispatchedModifierRef.current.has(item.modifierId)) {
        dispatch(saveModifier(item));
        dispatchedModifierRef.current.add(item.modifierId);
      }
    });
  };

  const deletemodifierFunc = (item) => {
    dispatchedModifierRef.current.delete(item.modifierId);
    setselectedModifierObject(item);
    dispatch(deleteAModifier(selectedModifierObject));
  };

  const onSubmit = handleSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    let res;
    let mydata;

    try {
      if (edit) {
        const id = drawerItem?.menuItemId;
        if (data.image instanceof File) {
          const response = await fetch(data?.image?.preview);
          const blob = await response.blob();
          const Filename = extractFileName(data?.image?.path);
          const imgRes = await createImage(blob, Filename);

          data = {
            ...data,
            image:
              imgRes === null || undefined || data.image === null || undefined
                ? {}
                : imgRes.data[0],
          };
        }
        if (savedModifiers?.length > 0) {
          const extractedModifierIds = savedModifiers.map((item) => item.modifierId);
          data.modifierGroups = extractedModifierIds;
        } else {
          data.modifierGroups = [];
        }

        res = await EditMenuItem(id, data);
        if (res.status === 200) {
          enqueueSnackbar('changes successfully added!', { variant: 'success' });
        } else if (res.status !== 200 || res.status === 404) {
          enqueueSnackbar('Unable to save the changes', { variant: 'error' });
        }
      } else {
        let imgRes;
        if (data.image instanceof File) {
          const response = await fetch(data?.image?.preview);
          const blob = await response.blob();
          const Filename = extractFileName(data?.image?.path);
          imgRes = await createImage(blob, Filename);
        }
        mydata = {
          ...data,
          image:
            imgRes === null || undefined || data.image === null || undefined ? {} : imgRes.data[0],
        };
        if (savedModifiers?.length > 0) {
          const extractedModifierIds = savedModifiers.map((item) => item.modifierId);
          mydata.modifierGroups = extractedModifierIds;
        } else {
          mydata.modifierGroups = [];
        }
        res = await createMenuItem(mydata);
        if (res.status === 200) {
          enqueueSnackbar('changes successfully added!', { variant: 'success' });
        } else if (res.status !== 200 || res.status === 404) {
          enqueueSnackbar('Unable to save the changes', { variant: 'error' });
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    if (edit) {
      // modifying the modifier in redux saveitems
      const editedItem = savedItem.map((item) => {
        if (item.menuItemId === res.data.menuItemId) {
          return res.data;
        }
        return item;
      });
      dispatch(replaceMyItem(editedItem));
    }
    if (!edit && flow) {
      dispatch(saveMyItem(res.data));
    }
    dispatchedModifierRef.current.clear();
    dispatch(removeModifier());
    setselectedModifiers([]);
    handleClose();
    reset();
    setLoading(false);
  });

  const handleClose = () => {
    closeItem(false);
    dispatch(removeModifier());
  };

  const [priceStrategy, setpriceStrategy] = useState('');

  const handleButtonClick = useCallback(
    (strategy) => {
      setpriceStrategy(strategy);
      setValue('PriceStrategy', strategy);
    },
    [setValue]
  );

  const addGroupRef = useRef(null);
  const scrollToSection = useCallback((section) => {
    if (addGroupRef.current) {
      const targetSection = addGroupRef.current.querySelector(`#${section.replace(/ /g, '-')}`);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });

        const lastItem = FORMDETAILSADDITEMS[FORMDETAILSADDITEMS.length - 1];
        const lastItemElement = addGroupRef.current.querySelector(
          `#${lastItem.replace(/ /g, '-')}`
        );
        const containerBottom = addGroupRef.current.getBoundingClientRect().bottom;
        const lastItemBottom = lastItemElement.getBoundingClientRect().bottom;

        if (lastItemBottom > containerBottom) {
          addGroupRef.current.scrollTo({
            top: addGroupRef.current.scrollTop + (lastItemBottom - containerBottom),
            behavior: 'smooth',
          });
        }
      }
    }
  }, []);

  /// container functions
  const renderInfo = (
    <Stack
      id="General-Info"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, boxShadow: 3 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px' }}>
          General Info{' '}
        </Typography>
      </Box>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Item Name</Typography>
        <RHFTextField name="menuItemName" label="Item Name" />
      </Stack>
      <Stack spacing={1.5} sx={{ my: 2 }}>
        <RHFSelect
          native
          name="availability"
          label="Availability"
          InputLabelProps={{ shrink: true }}
        >
          {AVAILABILITY?.map((item) => (
            <option key={item.label} value={Boolean(item.value)}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Stack>

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Pos nickname (if different than item)</Typography>
        <RHFTextField name="nickName" label="Pos nickname (if different than item)" />
      </Stack> */}

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography> POS Color Button</Typography>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={COLOR_OPTIONS}
            />
          )}
        />
      </Stack>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Image</Typography>
        <RHFUpload
          thumbnail
          name="image"
          maxSize={20000000}
          onDrop={handleDrop}
          onDelete={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={() => console.info('ON UPLOAD')}
        />
      </Stack>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Description</Typography>
        <RHFTextField name="description" label="Description" multiline rows={4} />
      </Stack>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Item Code</Typography>
        <RHFTextField name="itemCode" label="Item Code" type="number" />
      </Stack>
    </Stack>
  );
  const PricingTaxes = (
    <Stack
      id="Pricing-and-Taxes"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography>Pricing and Taxes </Typography>
      <Stack spacing={3} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Price Strategy </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
          <Button
            variant="outlined"
            sx={{
              borderColor: priceStrategy === 'basePrice' ? `${palette.primary.main}` : 'black',
              backgroundColor:
                priceStrategy === 'basePrice' ? `${palette.primary.main}` : 'initial',
              color: priceStrategy === 'basePrice' ? 'white' : 'black',
              '&:hover': {
                backgroundColor: `${palette.primary.main}`,
                color: 'white',
              },
            }}
            onClick={() => handleButtonClick('basePrice')}
          >
            Base Price
          </Button>

          <Button
            variant="outlined"
            sx={{
              borderColor: priceStrategy === 'sizePrice' ? `${palette.primary.main}` : 'black',
              backgroundColor:
                priceStrategy === 'sizePrice' ? `${palette.primary.main}` : 'initial',
              color: priceStrategy === 'sizePrice' ? 'white' : 'black',
              '&:hover': {
                backgroundColor: `${palette.primary.main}`,
                color: 'white',
              },
            }}
            onClick={() => handleButtonClick('sizePrice')}
          >
            Size Price
          </Button>

          <Button
            variant="outlined"
            sx={{
              borderColor: priceStrategy === 'openPrice' ? `${palette.primary.main}` : 'black',
              backgroundColor:
                priceStrategy === 'openPrice' ? `${palette.primary.main}` : 'initial',
              color: priceStrategy === 'openPrice' ? 'white' : 'black',
              '&:hover': {
                backgroundColor: `${palette.primary.main}`,
                color: 'white',
              },
            }}
            onClick={() => handleButtonClick('openPrice')}
          >
            Open Price
          </Button>

          <Button
            variant="outlined"
            sx={{
              borderColor: priceStrategy === 'setBasePrice' ? `${palette.primary.main}` : 'black',
              backgroundColor:
                priceStrategy === 'setBasePrice' ? `${palette.primary.main}` : 'initial',
              color: priceStrategy === 'setBasePrice' ? 'white' : 'black',
              '&:hover': {
                backgroundColor: `${palette.primary.main}`,
                color: 'white',
              },
            }}
            onClick={() => handleButtonClick('setBasePrice')}
          >
            Set Base Price
          </Button>
        </Box>
      </Stack>

      <Stack spacing={3} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Tax Rates</Typography>
        <RHFMultiCheckbox
          sx={{ display: 'flex', flexDirection: 'column' }}
          row
          spacing={4}
          name="taxRates"
          options={updatedTaxRates}
        />
      </Stack>
      <Divider />
      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Price</Typography>
        <RHFTextField name="price" label="Price" type="number" />
      </Stack>
    </Stack>
  );

  const ModifierGroups = (
    <Stack
      sx={{ backgroundColor: 'white', py: 3, px: 2, my: 2, width: '100%', boxShadow: 3 }}
      id="Modifier-Groups"
    >
      <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px' }}>
        Modifier Groups
      </Typography>
      <Box sx={{ width: '100%' }}>
        {savedModifiers?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 2,
              boxShadow: '0px 10px 5px rgba(0, 167, 111, 1)',
              border: 1,
              borderRadius: '8px',
              my: 2,
              width: '100%',
            }}
          >
            <Box>
              <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
                {item?.name}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {item?.options?.map((opt) => (
                  <Typography
                    sx={{ ml: 1, color: '#637381', fontSize: '14px', fontWeight: '400px' }}
                  >
                    {opt?.name},
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box>
              <Iconify
                onClick={(event) => {
                  seteditModifier(true);
                  setopenExistingItemDrawer(true);
                  setselectedModifierObject(item);
                  // selectedObjectRef.current = item;
                }}
                icon="eva:edit-outline"
                sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
              />
              <Iconify
                onClick={(event) => {
                  deletemodifierFunc(item);
                }}
                icon="eva:trash-2-outline"
                sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Button
          variant="outlined"
          color="success"
          sx={{ width: '300px', mt: 4 }}
          onClick={handleOpenExistingItemDrawer}
        >
          <Box sx={{ mr: 1 }}>+</Box> Add a New Modifier Group
        </Button>

        <Stack spacing={1.5} sx={{ my: 2, width: '80%' }}>
          <Container>
            <Autocomplete
              multiple
              id="tags-standard"
              options={ModifierList || []}
              getOptionLabel={(option) => option?.name}
              filterSelectedOptions
              value={selectedModifiers}
              // defaultValue={[top100Films[13]]}
              onChange={(event, list, reason, detail) => {
                handleAutocompleteChange2(event, list, reason, detail);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Add from existing modifiers"
                  // placeholder="Favorites"
                />
              )}
            />
          </Container>
        </Stack>
      </Box>
    </Stack>
  );

  const KitchenPrep = (
    <Stack
      id="Kitchen-prep"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px' }}>
        Kitchen prep
      </Typography>
      <Stack spacing={3} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Food Options</Typography>
        <RHFMultiCheckbox
          sx={{ display: 'flex', flexDirection: 'column' }}
          row
          spacing={4}
          name="prepStations"
          options={updatedPrepStations}
        />
      </Stack>
    </Stack>
  );
  const ChannelVisibility = (
    <Stack
      id="Channel-Visibility"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px', my: 2 }}>
        Channel Visibility
      </Typography>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              POS
            </Typography>
          </Box>
          <RHFSwitch name="pos" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              KIOSK
            </Typography>
          </Box>

          <RHFSwitch name="kiosk" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            my: 2,
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography sx={{ color: 'black', fontSize: '12px', fontWeight: '600px' }}>
              Online Ordering
            </Typography>
          </Box>

          <RHFSwitch name="onlineOrder" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
    </Stack>
  );
  const Versions = (
    <Stack
      id="Versions"
      sx={{
        backgroundColor: 'white',
        width: '100%',
        padding: 2,
        my: 6,
        boxShadow: 3,
      }}
    >
      <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px' }}>
        Versions
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
        <Typography sx={{ fontSize: '16px', color: 'black', fontweight: '400px' }}>
          This item does not have any version
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
        backgroundColor: '#F5F5F5',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box sx={{ backgroundColor: '#F5F5F5', height: '80vh' }}>
            <Stack sx={{ pt: 2, backgroundColor: '#F5F5F5' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'between',
                  alignItems: 'center',

                  px: 10,
                }}
              >
                <Iconify
                  icon="eva:close-fill"
                  sx={{
                    width: '30px',
                    height: '30px',
                    pl: '-2rem',
                    cursor: 'pointer',
                  }}
                  onClick={handleClose}
                />
                <Typography sx={{ fontSize: '32px', lineHeight: '48px', fontWeight: '700px' }}>
                  Item
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 14,
                }}
              >
                {breadcrumb && <CustomBreadcrumbs links={breadcrumb} sx={{ pb: 5 }} />}
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Stack>

            <Scrollbar
              sx={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}
            >
              <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Stack sx={{ position: 'fixed', top: '200px' }}>
                    {FORMDETAILSADDITEMS?.map((item, index) => (
                      <Box
                        sx={{
                          fontSize: '14px',
                          fontWeight: '600',
                          color: '#8E8E8E',
                          borderLeft: 4,
                          borderColor: 'gray',
                          px: '8px',
                          py: '8px',
                          '&:hover': {
                            color: `${palette.primary.main}`,
                            borderColor: `${palette.primary.main}`,
                          },
                          cursor: 'pointer',
                        }}
                        key={index}
                        onClick={() => scrollToSection(item)}
                      >
                        {item}
                      </Box>
                    ))}
                  </Stack>
                </Grid>
                <Grid
                  ref={addGroupRef}
                  sm={8}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Container>
                    <Stack sx={{ width: '90%' }}>
                      {renderInfo}
                      {PricingTaxes}
                      {ModifierGroups}
                      {KitchenPrep}
                      {ChannelVisibility}
                      {Versions}
                    </Stack>
                  </Container>
                </Grid>
              </Grid>
            </Scrollbar>
          </Box>
        </FormProvider>
      </Stack>
      <Drawer
        open={openExistingItemDrawer}
        anchor="right"
        onClose={handleCloseExistingItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddModifier
          groupItem={selectedModifierObject}
          closeFunc={handleCloseExistingItemDrawer}
          edit={editModifier}
        />
      </Drawer>
    </Box>
  );
};

AddItem.propTypes = {
  pathname: PropTypes.string,
  createMenuRef: PropTypes.object,
  closeItem: PropTypes.func,
  breadcrumb: PropTypes.array,
  drawerItem: PropTypes.object,
  edit: PropTypes.bool,
  flow: PropTypes.bool,
};
export default memo(AddItem);
