import PropTypes from 'prop-types';
import { Box, Container, Drawer } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import { mutate } from 'swr';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import { endpoints } from 'src/utils/axios';
import UserTableToolbar from 'src/sections/user/user-table-toolbar';
import { _userList } from 'src/_mock';
import { useTable } from 'src/components/table';
import { removeAddGroup, removeModifier, removeSaveMyItem } from 'src/features/group/groupSlice';
import { useGetModifiers } from 'src/api/apis';
import Iconify from '../iconify';
import CustomTable from '../custom-table/custom-table';
import AddModifier from '../add-modifier';

export default function ModifierSection({ OpenMenu }) {
  const [openItemDrawer, setopenItemDrawer] = useState(false);
  const [, setopenGroupDrawer] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [tableData, setTableData] = useState(_userList);
  const group = useSelector((state) => state.group.group);
  const dispatch = useDispatch();
  const table = useTable();

  const defaultFilters = {
    search: '',
  };
  const [filters, setFilters] = useState(defaultFilters);
  const { data: modifiersList } = useGetModifiers(filters.search);

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(
    () => () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    },
    [debounceTimeout]
  );

  const handleFilters = useCallback(
    (name, value) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeoutId = setTimeout(() => {
        table.onResetPage();
        setFilters((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }, 1500);

      setDebounceTimeout(timeoutId);
    },
    [debounceTimeout, table]
  );

  const handleCloseItemDrawer2 = (val) => {
    mutate(endpoints.modifier.modifiers);
    setopenItemDrawer(val);
    setopenGroupDrawer(val);
    dispatch(removeModifier());
    dispatch(removeSaveMyItem());
  };
  const handleCloseItemDrawer = () => {
    mutate(endpoints.modifier.modifiers);
    setopenItemDrawer(false);
    setopenGroupDrawer(false);
    dispatch(removeModifier());
    dispatch(removeSaveMyItem());
  };
  const handleOpenItemDrawer = useCallback(() => {
    if (group !== null || group !== undefined) {
      setopenItemDrawer(true);
      setopenGroupDrawer(false);
    }
  }, [group]);

  useEffect(() => {
    if (openItemDrawer) {
      // You can perform additional actions if needed
    }
  }, [openItemDrawer]);

  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          width: '100%',
          height: '100%',
          OverflowY: 'scroll',
        }}
      >
        <Stack>
          <Container
            sx={{
              mt: { xs: 2, sm: 0, md: 0, lg: 0 },
              width: '100',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              size="medium"
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" sx={{ xs: 10, sm: 20 }} />}
              target="_blank"
              rel="noopener"
              onClick={handleOpenItemDrawer}
            >
              Create new modifier
            </Button>
          </Container>
        </Stack>
        <>
          <Container>
            <UserTableToolbar
              filters={filters}
              onFilters={handleFilters}
              //
              // roleOptions={_roles}
              roleOptions={[]}
            />
          </Container>
          {/* {canReset && (
              <UserTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                onResetFilters={handleResetFilters}
                results={dataFiltered?.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )} */}
          <CustomTable list={modifiersList} myitems={group} modifiers />
        </>
      </Box>
      <Drawer
        open={openItemDrawer}
        anchor="right"
        onClose={handleCloseItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddModifier closeFunc={handleCloseItemDrawer2} edit={false} />
      </Drawer>
    </Container>
  );
}

ModifierSection.propTypes = {
  OpenMenu: PropTypes.func,
};
