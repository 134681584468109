/* eslint-disable dot-notation */
import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { useAuth0 } from '@auth0/auth0-react';
import axios, { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

let interceptorSet = false;

const setupAxiosInterceptors = (getAccessTokenSilently) => {
  if (interceptorSet) {
    return;
  }
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  interceptorSet = true;
};

function setupGetData(url){
  return function useData(search){
    const { getAccessTokenSilently } = useAuth0();
    setupAxiosInterceptors(getAccessTokenSilently);

    const URL = search ? `${url}?search=${search}` : url;
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

    return useMemo(()=>({
      data: data || [],
      isLoading,
      error,
      isValidating,
    }),
    [data, isLoading, error, isValidating]);
  };
}

export const useGetMenus = setupGetData(endpoints.menu.menu);
export const useGetAllGroups = setupGetData(endpoints.menuGroup.menu);
export const useGetAllItems = setupGetData(endpoints.menuItem.menu);
export const useGetModifiers = setupGetData(endpoints.modifier.modifiers);
export const useGetAllPrepStations = setupGetData(endpoints.prepStations.prep);
export const useGetTaxRates = setupGetData(endpoints.taxRates.taxrates);

export async function createMenu(payload) {
  const URL = endpoints.menu.menu;
  const res = await axios.post(URL, payload, {});
  return res;
}
export async function editMenu(id, payload) {
  const URL = endpoints.menu.menu;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function EditMenuItem(id, payload) {
  const URL = endpoints.menuItem.menu;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function createMenuItem(payload) {
  const URL = endpoints.menuItem.menu;
  const res = await axios.post(URL, payload, {});
  return res;
}

export async function createMenuGroup(payload) {
  const URL = endpoints.menuGroup.menu;
  const res = await axios.post(URL, payload, {});
  return res;
}
export async function editGroup(id, payload) {
  const URL = endpoints.menuGroup.menu;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function createImage(payload, filename) {
  const URL = endpoints.image.image;
  const res = await axios.post(`${URL}/${filename}`, payload, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
  return res;
}

export async function createModifier(payload) {
  const URL = endpoints.modifier.modifiers;
  const res = await axios.post(URL, payload, {});
  return res;
}

export async function editModifier(id, payload) {
  const URL = endpoints.modifier.modifiers;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function deleteModifier(id) {
  const URL = endpoints.modifier.modifiers;
  const res = await axios.delete(`${URL}/${id}`, {});
  return res;
}

export async function deleteMenu(id) {
  const URL = endpoints.menu.menu;
  const res = await axios.delete(`${URL}/${id}`, {});
  return res;
}

export async function DeleteMenuItem(id) {
  const URL = endpoints.menuItem.menu;
  const res = await axios.delete(`${URL}/${id}`);
  return res;
}

export async function deleteGroup(id) {
  const URL = endpoints.menuGroup.menu;
  const res = await axios.delete(`${URL}/${id}`);
  return res;
}
